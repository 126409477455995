import { THeadersResponse } from "@@types/headers";
import { EDeviceTypes } from "@enums";
import { ApiRepository } from "@repositories";
import { deviceDetected } from "@utils/common";
import { useEffect, useState } from "react";

const useDeviceDetected = () => {
  const [device, setDevice] = useState<EDeviceTypes>(EDeviceTypes.desktop);

  useEffect(() => {
    ApiRepository.getHeaders().then(({ headers }: THeadersResponse) => {
      if (!headers || typeof headers !== "object") return;
      const UA = headers["user-agent"];
      const device = deviceDetected(UA);
      setDevice(device);
    });
  }, []);

  return device;
};

export default useDeviceDetected;
