import React from "react";
import { MegaMenu, MegaMenuEmpty } from "magneto365.ui";
import { isMobile } from "react-device-detect";
import { TMegaMenuJobsResponse, TMegaMenuJobTab } from "@@types/megaMenu";
import { fullBasePath, jobsHostBase } from "@constants";

const { Cards, SideCards } = MegaMenu;

export const jobsTabAdapter = ({ jobs, slug, t, key, redirectAction }: TMegaMenuJobTab): TMegaMenuJobsResponse => {
  const url = `${fullBasePath}/${slug}`;

  return {
    label: t(`header:jobs.tabs.${key}`),
    url,
    content:
      jobs.length == 0 ? (
        <MegaMenuEmpty
          title={t(`header:jobs.empty.title`)}
          subtitle={t(`header:jobs.empty.subtitle`)}
          imageData={"waiting"}
        />
      ) : (
        <Cards
          jobs={jobs.map(({ counter, h1, slug, iconUrl, logoUrl }) => ({
            title: h1,
            quotas: counter,
            href: `${fullBasePath}/trabajos/${slug}`,
            onClick: redirectAction(`${fullBasePath}/trabajos/${slug}`),
            logo: logoUrl,
            icon: iconUrl
          }))}
          maxCards={isMobile ? 15 : 40}
          action={{ label: t(`header:jobs.viewAll`), url, onClick: redirectAction(url, false) }}
          title={t(`header:jobs.tabs.${key}`)}
          actionTitle={{ label: t(`header:jobs.actionTitle.${key}`), url, onClick: redirectAction(url, false) }}
        />
      )
  };
};

export const jobsSideTabAdapter = ({
  jobs,
  slug,
  t,
  key,
  onSelectCard,
  selected,
  redirectAction
}: TMegaMenuJobTab): TMegaMenuJobsResponse => {
  const url = `${fullBasePath}/${slug}`;
  return {
    label: t(`header:jobs.tabs.${key}`),
    url,
    content:
      jobs.length == 0 ? (
        <MegaMenuEmpty
          title={t(`header:jobs.empty.title`)}
          subtitle={t(`header:jobs.empty.subtitle`)}
          imageData={"waiting"}
        />
      ) : (
        <SideCards
          wrapperProps={{
            title: t(`header:jobs.tabs.${key}`),
            actionTitle: { label: t(`header:jobs.actionTitle.${key}`), url, onClick: redirectAction(url, false) },
            maxCards: 40,
            jobs:
              jobs.at(selected ?? 0)?.landings?.map(({ counter, h1, slug }) => ({
                title: h1,
                quotas: counter,
                href: `${jobsHostBase}/trabajos/${slug}`,
                onClick: redirectAction(`${jobsHostBase}/trabajos/${slug}`)
              })) ?? []
          }}
          sideProps={{
            maxCards: isMobile ? 15 : 12,
            jobs: jobs.map(({ h1, counter, slug }) => {
              const url = `${jobsHostBase}/trabajos/${slug}`;
              return {
                title: h1,
                href: url,
                quotas: counter,
                onClick: () => ({})
              };
            }),
            onSelectCard: onSelectCard,
            action: { label: t(`header:jobs.viewAll`), url, onClick: redirectAction(url, false) }
          }}
        />
      )
  };
};
