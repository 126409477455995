export const managerUserName = "epinilla";
export const FILTER_API =
  "?_fields=id,slug,status=publish,title,content,template,acf,uagb_featured_image_src,featured_media,parent,categories,excerpt";
export const FILTER_API_POST_PER_CATEGORIES =
  "&per_page=3&orderby=rand&_fields=id,slug,title,excerpt,status=publish,uagb_featured_image_src,featured_media";
export const FILTER_API_MENU = "&_fields=id,title,url,parent,target";
export const FILTER_API_COMMENTS = "&_fields=id,author_name,author,content,parent,status,date";

// ----------------------------------------

export const MANAGER_MENUS = [
  { name: "Menu Soluciones", id: 41 },
  { name: "Menu Negocios", id: 32 }
];

export const SEO_POLICIES = "politicas";
export const SEO_HOME = "home";
export const SEO_HOME_V2 = "home-b2c-v2";
export const SEO_BLOG = "blog";
export const SEO_BLOG_BUSINESS = "soluciones/blog-empresas";
export const SEO_FAQ = "preguntas-frecuentes";
export const SEO_COMPANY = "soluciones";
export const SEO_DIGITAL_SELECTION = "soluciones/seleccion-digital";
export const SEO_INTEGRAL_EVALUATION = "soluciones/evaluacion-integral";
export const SEO_ADME = "soluciones/seleccion-digital/agencia-de-marca-empleadora";
export const SEO_BUSINESS = "soluciones/pymes";
export const SEO_FAQ_BUSINESS = "soluciones/pymes/preguntas-frecuentes/";
export const SEO_PLANS_BUSINESS = "soluciones/pymes/planes/";

export const BLOG_BUSINESS_PAGE_SLUG = "blog-empresas";
export const PLANS_BUSINESS_PAGE_SLUG = "planes";
export const LABOR_SETTLEMENT_CALCULATOR = "calculadora-liquidacion-laboral";
export const VACANT_POSITION_SLUG = "cargos";
export const CALCULATOR_4_1000 = "calculadora-4-x-1000";

export const HOME_PAGE_ID = 141;
export const HOME_PAGE_V2_ID = 124417;
export const FAQ_PAGE_ID = 118012;
export const POLICIES_PARENT_ID = 120273;
export const COMPANY_ID = 121519;
export const DIGITAL_SELECTION_PAGE_ID = 121861;
export const INTEGRAL_EVALUATION_PAGE_ID = 121554;
export const ADME_PAGE_ID = 121728;
export const BUSINESS_PAGE_ID = 119461;
export const FAQ_BUSINESS_PAGE_ID = 119710;

export const SLIDER_HOME = 75; //CATEGORY "Slider - home b2c"
export const POST_TYPE_UPDATE = 78; //CATEGORY "Update"
export const MAIN_BLOG_CATEGORIES = { b2c: "personas", b2b: "empresas", pymes: "pymes", update: "update" };
export const MAIN_ID_BLOG_CATEGORIES = { idB2c: 11, idB2b: 26 };
