import getConfig from "next/config";
import { registryHost } from "@constants/environment.constant";

export const solutionsPath = "soluciones";
export const magnetoHome: string = getConfig().publicRuntimeConfig.urlHome || "https://www.magneto365.com/es";
export const urlCookiesInfo = `/politicas/politica-de-cookies-magneto-global/`;

export const instagram = "https://www.instagram.com/magnetoempleos/";
export const facebook = "https://www.facebook.com/MagnetoEmpleos?_rdc=4&_rdr";
export const linkedin = "https://www.linkedin.com/company/magnetoempleos/";
export const youtube = "https://www.youtube.com/c/Magnetoempleos";
export const tiktok = "https://www.tiktok.com/@magnetoempleos";
export const whatsapp = "https://api.whatsapp.com/send?phone=573117999999";

export const googlePlay = "https://play.google.com/store/apps/details?id=com.magneto365.app";
export const appStore = "https://apps.apple.com/co/app/magneto-empleo-para-todos/id6444812096";
export const appGallery = "https://appgallery.huawei.com/app/C108120693";
export const utmDownloadApp = "utm_source=website&utm_medium=modal_descarga_app_mobile&utm_campaign=";

export const blog = `${magnetoHome}/blog`;
export const knowUs = `${magnetoHome}conocenos/`;
export const frequentQuestions = `/preguntas-frecuentes`;
export const forCompanies = `/${solutionsPath}`;
export const shareUs = `/#comparte-magneto`;
export const jobsByCompany = `/empresas`;

export const dataProcessingAuthorization =
  "/politicas/autorizacion-de-tratamiento-de-datos-personales-magneto-global-s-a-s-nit-901-645-620-3";
export const personalDataPsyconometrics = "/politicas/politica-de-tratamiento-de-datos-personales-psyconometrics";
export const personalDataMagneto = "/politicas/politica-de-tratamiento-de-datos-personales-magneto-global-s-a-s";
export const noticeOfPrivacyPsyconometrics = "/politicas/aviso-de-privacidad-psyconometrics";
export const noticeOfPrivacyMagneto = "/politicas/aviso-de-privacidad-magneto-global";
export const termsAndConditions = "/politicas/terminos-condiciones-uso-plataforma-magneto";
export const SPEResolution =
  "https://static.magneto365.com/wordpress/2023/11/29085339/RES0333-AUTORIZACION-PSYCONOMETRICS-S.A.S.pdf";
export const SPEResolution0070 =
  "https://static.magneto365.com/wordpress/2024/05/14104035/RESOLUCION_0070_AUTORIZACION_-_MAGNETOGLOBAL_SAS-1-1.pdf";
export const userConsent = "/politicas/consentimiento-de-usuarios";
export const consentReferrals = "/politicas/declaracion-autorizacion-datos-referidos";
export const authorizationRegulations =
  "https://static.magneto365.com/wordpress/2023/11/29085340/Reglamento-de-prestacion-de-servicios-06.07.22-VF.pdf";
export const cookiePolicyPsyconometrics = "/politicas/politica-de-cookies-psyconometrics";
export const cookiePolicyMagneto = "/politicas/politica-de-cookies-magneto-global";
export const ethicsLineForm = "https://forms.office.com/r/cyHteAFN67";
export const mailingEthicsLine = "lineaetica@magnetoglobal.com";
export const policies = "/politicas";

export const siteMap = `${magnetoHome}/mapa-del-sitio`;
export const singUpHome = `/es/?action=sign-up`;
export const loginUrl = "https://login.magneto365.com/candidates";
export const marbleUrl = "https://www.marbleheadhunter.com";
export const digitalLibrarysUrl = "https://www.bibliotecasdigitales.co";
export const helpPage = "https://magnetoglobal.zendesk.com/hc/es-419/requests/new";
export const loginB2B = "https://app.magneto365.com/";
export const registerBusiness = `${registryHost}/pymes/registro`;

export const digitalSelection = `${magnetoHome}/${solutionsPath}/seleccion-digital`;
export const talentAssessment = `${magnetoHome}/${solutionsPath}/evaluacion-integral`;
export const getConsultancy = `${magnetoHome}/${solutionsPath}/#contacto`;
export const postJobOffer = `${magnetoHome}/${solutionsPath}/negocios`;
export const pymesPlans = `${magnetoHome}/${solutionsPath}/negocios/planes`;
