export { default as PagesRepository } from "./pages.repositories";
export { default as PostRepository } from "./posts.repositories";
export { default as AuthRepository } from "./auth.repository";
export { default as WidgetRepository } from "./widget.repository";
export { default as CptRepository } from "./cpt.repositories";
export { default as ApiRepository } from "./api.repositories";
export { default as MenuRepository } from "./menus.repositories";
export { default as PymesRepository } from "./pymes.repositories";
export { default as CommentsRepository } from "./commentsBlog.repositories";
export { default as MegaMenuRepository } from "./megaMenu.repository";
export { default as JobsRepository } from "./jobs.repository";
export { default as JobsWithAuthRepository } from "./jobsWithAuth.repository";
export { default as FiltersWithAuthRepository } from "./filtersWithAuth.repository";
export { default as FiltersRepository } from "./filters.repository";
