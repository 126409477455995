import { TJobsSchema } from "@@types/megaMenu";
import { fullBasePath, supportZendesk } from "@constants";
import { ELandingType } from "@enums/searchHit.enum ";
import { jobsSideTabAdapter, jobsTabAdapter } from "@utils/megaMenu/jobsByTypeAdapter.util";
import { SEO_BUSINESS, SEO_COMPANY, SEO_FAQ } from "./wordPressApi.constant";

const forCompanies = `${fullBasePath}/${SEO_COMPANY}`;
const frequentQuestions = `${fullBasePath}/${SEO_FAQ}`;
const postJobOffer = `${fullBasePath}/${SEO_BUSINESS}`;

export const socialHeaderTabs = [fullBasePath, postJobOffer, forCompanies];
export const socialHelpOptions = [frequentQuestions, supportZendesk];

export const jobsSchema: TJobsSchema = {
  [ELandingType.BY_LOCATION]: {
    slug: "trabajos/empleos-por-ciudades",
    adapter: jobsSideTabAdapter,
    type: ELandingType.BY_LOCATION
  },
  [ELandingType.BY_OCCUPATION]: {
    slug: "trabajos/empleos-por-cargo",
    adapter: jobsSideTabAdapter,
    type: ELandingType.BY_OCCUPATION
  },
  [ELandingType.BY_SECTOR]: {
    slug: "trabajos/empleos-por-sector-laboral",
    adapter: jobsTabAdapter,
    type: ELandingType.BY_SECTOR
  },
  [ELandingType.BY_COMPANY]: {
    slug: "empresas",
    adapter: jobsTabAdapter,
    type: ELandingType.BY_COMPANY
  }
};
