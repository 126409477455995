export const categoriesTitle = {
  "oficios-varios-aseo-seguridad": "Vigilantes y Aseadores",
  "manufactura-produccion-operativos": "Operarios de Producción",
  "construccion-oficios-artesanias": "Maestros de Obra y Artesanos",
  "tecnicos-reparaciones-mantenimiento": "Técnicos de Mantenimiento ",
  "ventas-servicio-cliente-marketing": "Asesores Comerciales",
  "medios-arte-produccion-audiovisual": "Productores Audiovisuales",
  "direccion-gerencia": "Gerentes y Directores",
  "profesionales-consultorias-servicios": "Consultores Empresariales",
  "informatica-tecnologia-sistemas": "Profesionales de TI",
  ingenieria: "Ingenieros",
  "agricultura-recursos-naturales": "Agricultores y Trabajadores del Campo",
  "educacion-formacion-docencia": "Docentes y Profesionales Educativos",
  "salud-medicina": "Médicos y Profesionales de la Salud",
  "ciencia-investigacion": "Científicos e Investigadores",
  "hoteleria-turismo-gastronomia": "Profesionales del Turismo y la Hotelería"
};
