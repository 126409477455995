export enum ELandingType {
  "BY_OCCUPATION" = "by-occupation",
  "BY_OCCUPATION_CATEGORY" = "by-occupation-category",
  "BY_LOCATION_STATE" = "by-location-state",
  "SEARCH_HIT" = "search-hit",
  "BY_SECTOR" = "by-sector",
  "BY_COMPANY" = "by-company",
  "BY_LOCATION" = "by-location",
  "BY_LOCATION_OCUPACOL" = "by-location-ocupacol",
  "BY_OCCUPATION_WITH_LOCATION" = "by-occupation-with-location"
}

export enum ECountries {
  US = "us",
  AR = "ar",
  CL = "cl",
  CO = "co",
  CR = "cr",
  DO = "do",
  EC = "ec",
  MX = "mx",
  NI = "ni",
  PA = "pa",
  SV = "sv",
  BR = "br"
}

export enum EOcupacolLandingSlug {
  "occupationByCitySlug" = "occupationByCitySlug"
}
