import { isServerSide } from "@constants/environment.constant";

export const getCookieClientSide = (cookieName: string): string | null => {
  if (isServerSide) return null;
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0].trim() === cookieName) {
      return cookie[1];
    }
  }
  return null;
};
