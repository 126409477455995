import {
  authorizationRegulations,
  blog,
  dataProcessingAuthorization,
  digitalLibrarysUrl,
  digitalSelection,
  ethicsLineForm,
  frequentQuestions,
  getConsultancy,
  jobsHostBase,
  loginUrl,
  marbleUrl,
  personalDataMagneto,
  policies,
  postJobOffer,
  pymesPlans,
  supportZendesk,
  talentAssessment,
  termsAndConditions
} from "@constants";

export const footerMagnetoUI = {
  personsMenu: {
    heightContent: 330,
    isLoading: false,
    title: "footer:titles.persons",
    isAuthenticated: false,
    links: [
      {
        tag: "footer:links.allJobs",
        routePath: `routes:jobs`,
        baseUrl: jobsHostBase,
        isExternal: false
      },
      {
        tag: "footer:links.login",
        routePath: "",
        baseUrl: loginUrl,
        isExternal: true
      },
      {
        tag: "footer:links.registerResume",
        routePath: "",
        baseUrl: "?action=sign-up",
        isExternal: false
      },
      {
        tag: "footer:links.blog",
        routePath: "",
        baseUrl: blog,
        isExternal: true
      },
      {
        tag: "footer:links.frequentQuestions",
        routePath: "",
        baseUrl: frequentQuestions,
        isExternal: true
      },
      {
        tag: "footer:links.jobsByCity",
        routePath: "routes:byCity",
        baseUrl: jobsHostBase,
        isExternal: false
      },
      {
        tag: "footer:links.jobsByIndustry",
        routePath: "routes:bySector",
        baseUrl: jobsHostBase,
        isExternal: false
      },
      {
        tag: "footer:links.jobsByCompany",
        routePath: "routes:byCompany",
        baseUrl: jobsHostBase,
        isExternal: true
      },
      {
        tag: "footer:links.support",
        routePath: "",
        baseUrl: supportZendesk,
        isExternal: true,
        openNewTab: true
      }
    ]
  },
  magnetoMenu: {
    heightContent: 140,
    title: "footer:titles.magneto",
    links: [
      {
        tag: "footer:links.digitalSelection",
        routePath: "",
        baseUrl: digitalSelection,
        isExternal: true
      },
      {
        tag: "footer:links.talentAssessment",
        routePath: "",
        baseUrl: talentAssessment,
        isExternal: true
      },
      {
        tag: "footer:links.getConsultancy",
        routePath: "",
        baseUrl: getConsultancy,
        isExternal: true
      }
    ]
  },
  magnetoLiteMenu: {
    heightContent: 115,
    title: "footer:titles.magnetoLite",
    links: [
      {
        tag: "footer:links.postJobOffer",
        routePath: "",
        baseUrl: postJobOffer,
        isExternal: true
      },
      {
        tag: "footer:links.pymesPlans",
        routePath: "",
        baseUrl: pymesPlans,
        isExternal: true
      }
    ]
  },
  otherSolutionsMenu: {
    heightContent: 115,
    title: "footer:titles.otherSolutions",
    links: [
      {
        tag: "footer:links.marble",
        routePath: "",
        baseUrl: marbleUrl,
        isExternal: true
      },
      {
        tag: "footer:links.library",
        routePath: "",
        baseUrl: digitalLibrarysUrl,
        isExternal: true
      }
    ]
  },
  legalMenu: {
    heightContent: 335,
    title: "footer:titles.legal",
    links: [
      // {
      //   tag: "footer:links.personalDataPsyconometrics",
      //   routePath: "",
      //   baseUrl: personalDataPsyconometrics,
      //   isExternal: true
      // },
      {
        tag: "footer:links.personalDataMagneto",
        routePath: "",
        baseUrl: personalDataMagneto,
        isExternal: true
      },
      {
        tag: "footer:links.dataProcessingAuthorization",
        routePath: "",
        baseUrl: dataProcessingAuthorization,
        isExternal: true
      },
      // {
      //   tag: "footer:links.noticeOfPrivacyPsyconometrics",
      //   routePath: "",
      //   baseUrl: noticeOfPrivacyPsyconometrics,
      //   isExternal: true
      // },
      // {
      //   tag: "footer:links.noticeOfPrivacyMagneto",
      //   routePath: "",
      //   baseUrl: noticeOfPrivacyMagneto,
      //   isExternal: true
      // },
      {
        tag: "footer:links.termsAndConditions",
        routePath: "",
        baseUrl: termsAndConditions,
        isExternal: true
      },
      // {
      //   tag: "footer:links.consentReferrals",
      //   routePath: "",
      //   baseUrl: consentReferrals,
      //   isExternal: true
      // },
      {
        tag: "footer:links.authorizationRegulations",
        routePath: "",
        baseUrl: authorizationRegulations,
        isExternal: true
      },
      // {
      //   tag: "footer:links.cookiePolicyPsyconometrics",
      //   routePath: "",
      //   baseUrl: cookiePolicyPsyconometrics,
      //   isExternal: true
      // },
      // {
      //   tag: "footer:links.cookiePolicyMagneto",
      //   routePath: "",
      //   baseUrl: cookiePolicyMagneto,
      //   isExternal: true
      // },
      // ----------------------------------
      {
        tag: "footer:links.ethicsLineForm",
        routePath: "",
        baseUrl: ethicsLineForm,
        isExternal: true
      },
      {
        tag: "footer:links.sustainability",
        routePath: "",
        baseUrl: policies,
        isExternal: true
      }
    ]
  }
};
