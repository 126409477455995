import { AxiosRequestConfig } from "axios";
import { TEndpoint } from "#types/api.core";
import { TOriginsLinkups } from "#types/candidate";
import { TJobQuestions, TSendAnswers } from "#types/detail";
import { isClientSide } from "@constants/environment.constant";
import { getToken } from "@utils/auth";
import { getAllUtms } from "@utils/url";
import { EVacantStates } from "@enums/vacantStates.enum";
import { JobsRepository } from "./jobs.repository";

export class JobsWithAuthRepository extends JobsRepository {
  //TODO: move to config file
  protected readonly endpoints: TEndpoint = {
    jobs: {
      search: "/v1/jobs/search",
      detail: "/v1/jobs/{jobSlug}",
      marks: "/v1/jobs/setting/{id}",
      suggested: "/v1/public/jobs/vacants/suggested/{jobSlug}",
      similar: "/v2/jobs/suggested/mimir-wisdom",
      jobQuestionnaire: "/v1/questionnaire/{jobId}",
      apply: "/v1/jobs/apply",
      save: "/v1/jobs/state",
      unsave: "/v1/jobs/state/{jobId}/{state}"
    },
    candidate: {
      linkups: "/v1/jobs/candidate/linkup-information"
    },
    // TODO: Remove this endpoint in other repository
    filters: {
      countries: "/v1/public/countries/{companySlug}"
    },
    matcher: {
      declineInvitation: "/v1/matcher/decline"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(baseOptions);
    this.api.interceptors.request.use(this.useBearerToken);
  }

  private useBearerToken(initConfig: AxiosRequestConfig): AxiosRequestConfig {
    if (initConfig.headers && initConfig.headers["Authorization"]) return initConfig;
    if (isClientSide && initConfig.headers) {
      initConfig.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return initConfig;
  }

  async getJobQuestions(jobId: number): Promise<TJobQuestions> {
    return this.get<TJobQuestions>(this.endpoints.jobs.jobQuestionnaire, { params: { jobId } }).then(
      (response) => response.data
    );
  }

  async applyJob(body: TSendAnswers) {
    return this.post(this.endpoints.jobs.apply, { ...body, utmPayload: getAllUtms() });
  }

  async saveJob(jobId: number) {
    return this.post(this.endpoints.jobs.save, { jobId });
  }

  async unsaveJob(jobId: number) {
    const state = EVacantStates.save;
    return this.delete(this.endpoints.jobs.unsave, { params: { jobId, state } });
  }

  async getOriginsAndLinkups(): Promise<TOriginsLinkups> {
    return this.get<TOriginsLinkups>(this.endpoints.candidate.linkups).then((response) => response.data);
  }
}

export default new JobsWithAuthRepository();
