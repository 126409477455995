import { createContext } from "react";
// import { TJobsContext } from "@@types/jobs";
import { EDeviceTypes, ESortTypes } from "@enums/filter.core.enum";
import { TJobsContext } from "@@types/jobs";
export const initJobsContext: TJobsContext = {
  loading: false,
  jobDetailAction: null,
  internalLinking: [],
  similarVacancies: [],
  selectedJob: null,
  offerSuggested: [],
  countries: [],
  selectedJobFilter: null,
  landingPage: null,
  setContextValue: () => (value: TJobsContext) => value,
  searchResponse: { rows: [], counters: [], totalRows: 0, totalHits: 0 },
  filterSettings: {
    language: "unknown",
    version: "unknown",
    filters: [],
    pagination: {
      desktop: { defaultPageSize: 0, pageSizeOptions: [] },
      mobile: { defaultPageSize: 0, pageSizeOptions: [] }
    },
    order: {
      desktop: { default: { field: "unknown", order: ESortTypes.ASC }, fields: [] },
      mobile: { default: { field: "unknown", order: ESortTypes.ASC }, fields: [] }
    }
  },
  filtersApplied: {
    filters: {},
    q: null,
    paginator: { pageSize: 0, page: 0 },
    order: { field: "unknown", order: ESortTypes.ASC },
    device: EDeviceTypes.desktop
  },
  settingsJobSite: null,
  device: EDeviceTypes.desktop,
  jobQuestions: null,
  overdriveLibrary: [],
  onDetailJobSection: false
};

export const JobsContext = createContext<TJobsContext>(initJobsContext);

JobsContext.displayName = "JobsContext";
