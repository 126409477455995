import { TMegaMenuCard } from "@@types/megaMenu";
import { endpoints, host } from "@config/megaMenu.config.ts/megaMenuApi.config";
import ApiSeo from "@core/apiSeo.core";
import { ELandingType } from "@enums/searchHit.enum ";
import { AxiosRequestConfig } from "axios";
import { mergeRight } from "ramda";

export class MegaMenuRepository extends ApiSeo {
  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: host }, baseOptions));
  }

  async getJobsByType(type: ELandingType): Promise<TMegaMenuCard[]> {
    return this.get<TMegaMenuCard[]>(endpoints.megaMenu.byType, { params: { type } })
      .then((response) => response.data)
      .catch(() => []);
  }
  async getJobsByOccupationCategory(parentSlug: string): Promise<TMegaMenuCard[]> {
    return this.get<TMegaMenuCard[]>(endpoints.megaMenu.byOccupationCategory, { params: { parentSlug } })
      .then((response) => response.data)
      .catch(() => []);
  }
}

export default new MegaMenuRepository();
