import occupations from "@/data/output.json";
import { TVacantPositionInfo } from "@@types/vacantPositions";

export const getVacantPositionInfo = (slug = ""): TVacantPositionInfo | undefined => {
  const occupation: TVacantPositionInfo | undefined = occupations.find(
    ({ slug: occupationSlug }) => occupationSlug.trim() === slug
  );
  return occupation;
};

export const getcurrentAvailable = (): string[] => {
  return occupations.map(({ slug }) => slug);
};