import React, { Fragment } from "react";
import LayoutB2c from "./layoutB2C/layout.component";
import LayoutB2b from "./layoutB2B/layoutCompanies.component";
import { EDeviceTypes } from "@enums/filter.core.enum";
import LayoutEmpty from "./layoutEmpty/layoutEmpty.component";
import { BusinessType } from "@@types/gtm";
import LayoutPymes from "./layoutB2B/layoutPymes.component";

type Props = {
  children: React.ReactNode;
  type: BusinessType;
  device: EDeviceTypes;
  isAuthenticated?: boolean;
};

const Layout = ({ children, type, device }: Props) => {
  let layoutComponent;

  switch (type) {
    case "b2c":
      layoutComponent = <LayoutB2c device={device}>{children}</LayoutB2c>;
      break;
    case "b2b":
      layoutComponent = <LayoutB2b device={device}>{children}</LayoutB2b>;
      break;
    case "pymes":
      layoutComponent = <LayoutPymes device={device}>{children}</LayoutPymes>;
      break;
    case "empty":
      layoutComponent = <LayoutEmpty>{children}</LayoutEmpty>;
      break;
    default:
      layoutComponent = <LayoutB2c device={device}>{children}</LayoutB2c>;
  }

  return <Fragment>{layoutComponent}</Fragment>;
};

export default Layout;
