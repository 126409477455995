import { TCommentsBlog } from "@@types/wordPress";
import { FILTER_API_COMMENTS, managerUserName } from "@constants";
import { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { mergeRight } from "ramda";
import { TEndpoint } from "../@types/api.core";
import Api from "../core/api.core";

const { wpApiHost, wpApiHostPass } = getConfig().publicRuntimeConfig;

export class CommentsBlogRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    comments: {
      postComment: "/wp/v2/comments",
      getCommentsId: `/wp/v2/comments?post={id}${FILTER_API_COMMENTS}`
    }
  };
  constructor(baseOptions: AxiosRequestConfig = {}) {
    const authHeader = {
      Authorization: "Basic " + Buffer.from(managerUserName + ":" + wpApiHostPass).toString("base64")
    };
    super(mergeRight({ baseURL: `${wpApiHost}/wp-json`, headers: { ...authHeader } }, baseOptions));
  }

  async postComment(commentData: TCommentsBlog): Promise<TCommentsBlog> {
    return this.post<TCommentsBlog>(this.endpoints.comments.postComment, commentData).then((response) => response.data);
  }

  async getCommentsId(id: number): Promise<TCommentsBlog> {
    const endpoint = this.endpoints.comments.getCommentsId.replace("{id}", id.toString());
    return this.get<TCommentsBlog>(endpoint).then((response) => response.data);
  }
}

export default new CommentsBlogRepository();
